import React from 'react';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import ServicesCard from './ServicesCard.js';

function Services(props) {

	let cards = props.data.service.cards

	let displayServices = () => {
		return (
			cards.map(cardItem =>
				<ServicesCard key={cardItem.id}
					cardItem={cardItem} />
			)
		)
	};
	return (
		<React.Fragment>
			<Helmet>
				<title>Teeth for Keeps</title>
				<meta name="description" content="Your kid's favorite dentist." />
				<meta name="keywords" content="dentist for kids, teeth for keeps, san pedro, laguna" />
			</Helmet>
			<Fade bottom duration={2000}>
				<div id="services" className="jumbotron jumbo-services mb-0  pt-0">
					<h2 className="service-title font-weight-bold pb-4 pt-5 mx-auto text-center">What We Do</h2>

					<div className="row pt-2 pb-4">
						{displayServices()}
					</div>
				</div>
			</Fade>

		</React.Fragment>

	);
}

export default Services;