import React from 'react';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import Doctors from './Doctors.js';

function About(props) {
	let data = props.data.about;
	return(
		<div id="about" className="jumbotron jumbo-about d-flex justify-content-center mb-0">
			<Helmet>
                <title>Teeth for Keeps</title>
                <meta name="description" content="Your kid's favorite dentists." />
                <meta name="keywords" content="dentist for kids, teeth for keeps, san pedro, laguna" />
            </Helmet>
			<Fade bottom duration={3000}>
			<div className="about-section col-md-7 text-center">
				<h2 className="about-title font-weight-bold pb-4 pt-5"> {data.title} </h2>
				<h5 className="about-sub">
					{data.sub_line_1}
					<br/><br/>
					{data.sub_line_2}
				</h5>
				{/*<Doctors data={props.data}/>*/}
			</div> 
			</Fade>			
		</div>
	);
}

export default About;