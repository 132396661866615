import React from 'react';

function ServicesCard(props) {
	return(
		<div className="column col-md-4 col-12">
		    <div className={"card service-"+props.cardItem.id}>
		    	<div className="card-details">
		    		 <img className="p-2" src={require('../assets/images/'+props.cardItem.card_image_src)} alt=""/>
		    		 <div>
		    		 <h5 className="card-title p-2 font-weight-bold"> {props.cardItem.card_title} </h5>
		    		 <p className="card-sub">
		    		 	{props.cardItem.card_details}
		    		 </p>
		    		 </div>
		    	 </div>
			</div>
		</div>
		
	);
}

export default ServicesCard;
