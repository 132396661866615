import React from 'react';
import Spin from 'react-reveal/Spin';
import Jump from 'react-reveal/Jump';

function Footer(props) {
	return(
		<footer className="footer p-3 d-flex align-items-center">
	      	<div className="container text-center">
		        <Jump>
			        <div className="d-flex justify-content-center pb-2">
						<div className="socialIcons pt-3 d-flex justify-content-around w-50">
							<Spin>
							<a href="https://www.facebook.com/teethforkeeps" target="_blank">
								<i className="fab fa-facebook fa-2x" id="fb"></i>
							</a>
							<a href="https://m.me/teethforkeeps" target="_blank">
								<i class="fab fa-facebook-messenger fa-2x" id="messenger"></i>
							</a>
							</Spin>
						</div>
					</div>
				</Jump>	
		        <small className="text-muted">&copy; {props.data.footer.text}</small>
	    	</div>
	    </footer>
	);
}

export default Footer;