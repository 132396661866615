import React from 'react';
import Helmet from 'react-helmet';
import Fade from 'react-reveal/Fade';
import Spin from 'react-reveal/Spin';
import Jump from 'react-reveal/Jump';

function Contact(props) {
	let data = props.data.contact;
	// let displayContactDetails = () =>{
	// 	return(
	// 		data.items.map(item=> {
	//             <div className="media" key={item.id}>
	//                 <i className={item.icon}></i><span>{item.text}</span> 
	              
	//             </div>
	// 		})
	// 	);
	// 	console.log(data.items)
	// }

	return(
		<div id="contact" className="jumbotron jumbo-contact d-flex flex-row p-5">
			<Helmet>
                <title>Teeth for Keeps</title>
                <meta name="description" content="Your kid's favorite dentist." />
                <meta name="keywords" content="dentist for kids, teeth for keeps, san pedro, laguna" />
            </Helmet>
			<Fade right duration={2000}>
				<div className="contact-img col-md-7 col-12">	
				</div>
				<div className="contact-title-section col-md-5 col-12">
					<div className="contact-details-section pb-5">
						<hr/>
						<h3 className="contact-title pt-5 pb-4 font-weight-bold">{data.title}</h3>
						<div className="contact-details">
						 {
						 	data.items.map(item =>{
						 		return(
						            <div className="p-0 pt-2 pb-2" key={item.id}>
						                <i className={`${item.icon} pr-2`}></i><span>{item.text}</span> 
						              
						            </div>
						 		)
							})
						 }
						</div>
						{/*<Jump>
							<div className="socialIcons pt-3 pr-5 d-flex flex-row-reverse">
								<Spin>
								<a href="https://www.facebook.com/teethforkeeps" target="_blank">
									<i className="fab fa-facebook fa-2x" id="fb"></i>
								</a>
								</Spin>
							</div>
						</Jump>*/}
					</div>						
				</div>
			</Fade>
		</div>
	);
}

export default Contact;