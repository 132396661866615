import React from 'react';
import Fade from 'react-reveal/Fade';


function Header(props) {
	let data = props.data.header;
	let windowScrollTop;
	  if (window.innerWidth >= 768) {
	    windowScrollTop = window.pageYOffset / 7;
	  } else {
	    windowScrollTop = 0;
	  }
	  const [transform, setTransform] = React.useState(
	    "translate3d(0," + windowScrollTop + "px,0)"
	  );
	  React.useEffect(() => {
	    if (window.innerWidth >= 768) {
	      window.addEventListener("scroll", resetTransform);
	    }
	    return function cleanup() {
	      if (window.innerWidth >= 768) {
	        window.removeEventListener("scroll", resetTransform);
	      }
	    };
	  });
	  const resetTransform = () => {
	    var windowScrollTop = window.pageYOffset / 7;
	    setTransform("translate3d(0," + windowScrollTop + "px,0)");
	  };
	  
	return(
		<div id="header" className="jumbotron jumbo-header d-flex flex-row justify-content-around" style={{transform: transform}}>
			<div className="header-title-section col-lg-6 col-md-12 col-12">
				<hr/>
				<Fade bottom duration={2000}>
				<h1 id="header-main" className="header-title mt-5 font-weight-bold"> {data.header_main} </h1>
				</Fade>
				<div className="header-sub-section">
					<h3 id="header-sub" className="header-title mb-5">
		                {data.header_sub}
		            </h3>
	            </div>
				<hr />
			</div>
			<div className="header-img col-lg-6 col-md-10 col-10">
			</div>
			<a href="#about"><div className="header-arrow-section">
				<i className="fas fa-chevron-down fa-3x"></i>
			</div></a>
		</div>
	);
}

export default Header;
