import React from 'react';
// import Nav from './Nav.js';
import Header from './Header.js';
import Body from './Body.js';
// import About from './About.js';
// import Services from './Services.js';
// import Doctors from './Doctors.js';
import Contact from './Contact.js';
import Footer from './Footer.js';
import Nav from './Nav.js';
import MessengerCustomerChat from 'react-messenger-customer-chat';

function Tfk(props) {


	return (
		<React.Fragment>

			<Nav />
			<Header data={props.data} />
			<Body data={props.data} />
			{/*<Doctors data={props.data}/>*/}
			<Contact data={props.data} />
			<Footer data={props.data} />
			
		</React.Fragment>
	)
}

export default Tfk;