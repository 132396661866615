import React, {useEffect, useState} from 'react';
import {Link} from 'react-scroll';


function Nav() {
	let [navBarClass, setNavBarClass] = useState("smallNavBar");
	window.addEventListener('scroll', (event) => {
		console.log(navBarClass)
		console.log(window.pageYOffset)
		// console.log(document.getElementById("navbar").offsetTop)
     	if (window.pageYOffset > 50) {
			setNavBarClass("fixedElement")
		}else{
			setNavBarClass("smallNavBar")
		}
    });
	
	return(
		<div className={`container-fluid ${navBarClass} p-0`}>
			<nav id="nav" className="navbar navbar-expand-lg navbar-light ml-0 py-0">
				<a href="#header" className="navbar-brand">
					<img id="logo" src={require('../assets/images/finallogo.png')}/>
				</a>

				  <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
				    <span class="icon-bar top-bar"></span>
					<span class="icon-bar middle-bar"></span>
					<span class="icon-bar bottom-bar"></span>	
				  </button>

				<div id="navbar" className="collapse navbar-collapse">
					<ul className="navbar-nav ml-auto">
						<li className="nav-item" id="navbar-home">
							<Link id="nav-header" className="nav-link" activeClass="active" to="header" spy={true} smooth={true} offset={-300} duration={500}>HOME</Link>
						</li>

						<li className="nav-item">
							<Link id="nav-about" className="nav-link" activeClass="active" to="about" spy={true} smooth={true} offset={-100} duration={500}>
                                ABOUT
                            </Link>
						</li>

						<li className="nav-item">
							<Link id="nav-services" className="nav-link" activeClass="active" to="services" spy={true} smooth={true} offset={-20} duration={500}>
                                SERVICES
                            </Link>
						</li>

						<li className="nav-item">
							<Link id="nav-contact" className="nav-link" activeClass="active" to="contact" spy={true} smooth={true} offset={0} duration={500}>
                                CONTACT
                            </Link>
						</li>
					</ul>
				</div>
			</nav>
		</div>
		
	);
}

export default Nav;