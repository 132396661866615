import React from 'react';
import { BrowserRouter, Link, Route } from 'react-router-dom';
import Tfk from './components/Tfk.js';
import data from './data.json';

import MessengerCustomerChat from 'react-messenger-customer-chat';
// import Nav from './components/Nav.js';




function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <MessengerCustomerChat
              pageId="1431039770297117"
              appId="653941182672763"
              htmlRef={window.location.pathname}
        />
        <Tfk data={data}/>
      </div>
    </BrowserRouter>
  );
}

export default App;
