import React from 'react';
import About from './About.js';
import Services from './Services.js';

function Body(props){
	return(
		<div className="jumbotron jumbo-body">

	        <div className="container-body">
	          <About data={props.data}/>
	          <Services data={props.data}/>
	        </div>
	    </div>
	);
}

export default Body;